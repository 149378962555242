import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Spin, Table } from "antd";
import { renewOrderApi } from "api/renew-order.api";
import { Pagination } from "components/Pagination";
import React, { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { RenewOrder } from "types/renew";
import { formatVND } from "utils";
import { $url } from "utils/url";

const { ColumnGroup, Column } = Table;

export const RenewOrderComponent = ({}) => {
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 10,
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RenewOrder[]>([]);
  const [total, setTotal] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedRenew, setSelectedRenew] = useState<Partial<RenewOrder>>({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {}, []);

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = async () => {
    setLoading(true);
    const res = await renewOrderApi.findAll(query);
    setLoading(false);
    setData(res.data.renewOrders);
    setTotal(res.data.total);
  };

  return (
    <div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Search</label>
            <Input
              onKeyDown={(ev) => {
                if (ev.code == "Enter") {
                  setQuery({ ...query });
                }
              }}
              size="middle"
              onChange={(ev) => {
                query.search = ev.currentTarget.value;
              }}
              placeholder="Code, merchant"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={fetchData}
              type="primary"
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={data}
          scroll={{ x: 720 }}
        >
          <Column
            title="Code"
            dataIndex="code"
            render={(text) => <span>#{text}</span>}
          />
          <Column
            title="Amount"
            render={(text, record: RenewOrder) => (
              <span>${formatVND(record.amount)}</span>
            )}
          />
          <Column title="Day" dataIndex={"day"} />
          <Column
            title="Merchant"
            render={(text, record: RenewOrder) => (
              <div>
                {record.merchant.avatar && (
                  <>
                    <img width={60} src={$url(record.merchant.avatar)} alt="" />
                    <br />
                  </>
                )}
                <span>
                  {record.merchant.name} - {record.merchant.contactPhone}
                </span>
              </div>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          onChange={({ limit, page }) => {
            query.page = page;
            query.limit = limit;
            setQuery({ ...query });
          }}
        />
      </Spin>
    </div>
  );
};
