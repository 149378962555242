import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";
import { MerchantGallery } from "types/merchantGallery";
import { getToken } from "utils/auth";
import { $url } from "utils/url";

interface MultiImageUploadProps {
  uploadUrl?: string;
  imageUrl: string;
  onUploadOk: (fileList: Array<string>) => void;
  // fileListProp: UploadFile<any>[];
  fileListProp: UploadFile<any>[];
}

export const MultiImageUpload = ({
  uploadUrl = process.env.REACT_APP_API_URL + "/v1/admin/staff/upload",
  imageUrl,
  fileListProp,
  onUploadOk,
}: MultiImageUploadProps) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile<any>[]>();

  useEffect(() => {
    setFileList(fileListProp);
    console.log("filelistprop", fileListProp);
  }, [fileListProp]);

  // console.log("render - MultiImageUpload", imageUrl);

  // const getBase64 = (file: File) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  // const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || "");
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList }: { fileList: UploadFile<any>[] }) => {
    setFileList(fileList);
    console.log("filelist", fileList);
    const filePathList = fileList.map((file) => {
      return $url(file.response?.data?.path || file.url);
    });

    onUploadOk(filePathList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        multiple
        headers={{ token: getToken() || "" }}
        action={uploadUrl}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList && fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title="Preview image"
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
