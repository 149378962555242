import { Button, Form, Input } from "antd";
import "./styles/LoginPage.scss";
import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { userStore } from "store/userStore";
import { useNavigate } from "react-router-dom";

const { Item: FormItem } = Form;

export const LoginPage = () => {
  const [form] = Form.useForm<{ username: string; password: string }>();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const handleSubmit = async () => {
    console.log("handleSubmit:");
    setLoading(true);
    const { username, password } = form.getFieldsValue();
    try {
      await userStore.login(username, password);
      await userStore.getProfile();
      navigation("/");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div style={{ paddingTop: 120 }}>
        <div className="login-container">
          <div className="logo text-center">
            <img width={80} src={require("assets/images/logo.png")} />
            <h1>Admin</h1>
          </div>

          <Form onFinish={handleSubmit} form={form} layout={"vertical"}>
            <FormItem label="Username" name="username">
              <Input prefix={<UserOutlined />} size="large" />
            </FormItem>

            <FormItem label="Password" name="password">
              <Input.Password prefix={<LockOutlined />} size="large" />
            </FormItem>

            <FormItem>
              <Button
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
                type="primary"
                size="large"
              >
                Login
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};
