import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { merchantApi } from "api/merchant.api";
import React, { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";

const exampleText = "Hi $FULLNAME , confirm text  $TIME, at $MERCHANT_NAME";

export const SmsSetupPage = ({
  merchantId,
  status,
  visible,
}: {
  merchantId: number;
  status: ModalStatus;
  visible: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Merchant>();
  const [confirmText, setConfirmText] = useState(
    "Hi Alex Leon , confirm text Fri, 04-15-2022 at 4:44 pm, at Phuc Binh Salon"
  );
  const [cancelText, setCancelText] = useState(
    "Hi Alex Leon , confirm text Fri, 04-15-2022 at 4:44 pm, at Phuc Binh Salon"
  );

  const [form] = useForm();

  useEffect(() => {
    if (data && status == "update" && visible) {
      form.setFieldsValue({ ...data });
      if (!data.templateCancellation && !data.templateConfirmation) {
        form.setFieldsValue({
          templateCancellation: exampleText,
          templateConfirmation: exampleText,
        });
      }
    }
  }, [data, status, visible]);

  useEffect(() => {
    if (visible && status == "create") {
      form.resetFields();
      form.setFieldsValue({
        templateCancellation: exampleText,
        templateConfirmation: exampleText,
      });
    }
  }, [visible, status]);

  useEffect(() => {
    if (merchantId) {
      fetchData();
    }
  }, [merchantId]);

  const fetchData = async () => {
    setLoading(true);
    const res = await merchantApi.detail(merchantId);
    setLoading(false);
    setData(res.data);
  };

  const handleSubmitForm = async () => {
    await form.validateFields();

    const formData = form.getFieldsValue();
    // const { images, ...rest } = formData;
    console.log("formdata", formData);

    const dataPost = {
      merchant: {
        ...formData,
      },
    };
    setLoading(true);
    await merchantApi.update(merchantId, dataPost);
    setLoading(false);
    message.success("Saved!");
  };

  const replaceText = (value: string, resultName = "string") => {
    const fullname = "Alex Leon";
    const time = "Fri, 04-15-2022 at 4:44 pm";
    const merchantName = data?.name;

    const result = value
      .replace("$FULLNAME", fullname)
      .replace("$TIME", time)
      .replace("$MERCHANT_NAME", merchantName || "");

    switch (resultName) {
      case "confirmText":
        setConfirmText(result);
        break;

      case "cancelText":
        setCancelText(result);
        break;
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitForm}
        initialValues={{
          templateConfirmation:
            "Hi $FULLNAME , confirm text  $TIME, at $MERCHANT_NAME",
          templateCancellation:
            "Hi $FULLNAME , confirm text  $TIME, at $MERCHANT_NAME",
          // turnOnTextConfirmation: true,
          // turnOnTextCancellation: true,
        }}
      >
        <div
          className="sms-wrapper"
          style={{ marginBottom: "2em", marginTop: "2em", padding: "0 4em" }}
        >
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography.Title level={4}>Confirmation text</Typography.Title>
            <FormItem
              style={{ marginBottom: "0" }}
              valuePropName={"checked"}
              name={"turnOnTextConfirmation"}
            >
              <Switch style={{ marginBottom: "10px" }} />
            </FormItem>
          </Space>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Template confirmation text"
                name="templateConfirmation"
                // rules={[{ required: true }]}
              >
                <TextArea
                  onChange={(e) => replaceText(e.target.value, "confirmText")}
                  rows={5}
                  cols={12}
                  //   defaultValue='"Hi $FULLNAME , confirm text  $TIME, at $MERCHANT_NAME"'
                ></TextArea>
              </FormItem>

              <h2>Pattern required:</h2>
              <p>$FIRST_SERVICE_START: First service start</p>
              <p>$FULLNAME: Full name of customer</p>
              <p>$MERCHANT_NAME: Name of merchant</p>

              <p style={{ marginTop: "2em" }}>
                <strong>Result: </strong>
                {confirmText}
              </p>
            </Col>
          </Row>
        </div>
        <Divider />

        <div style={{ padding: "0 4em" }} className="sms-wrapper">
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography.Title level={4}>Cancellation text</Typography.Title>
            <FormItem
              style={{ marginBottom: "0" }}
              name={"turnOnTextCancellation"}
              valuePropName={"checked"}
            >
              <Switch style={{ marginBottom: "10px" }} />
            </FormItem>
          </Space>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Template cencellation text"
                name="templateCancellation"
                // rules={[{ required: true }]}
              >
                <TextArea
                  rows={5}
                  cols={12}
                  onChange={(e) => replaceText(e.target.value, "cancelText")}
                  value={
                    "Hi $FULLNAME , confirm text  $TIME, at $MERCHANT_NAME"
                  }
                />
              </FormItem>

              <h2>Pattern required:</h2>
              <p>$FIRST_SERVICE_START: First service start</p>
              <p>$FULLNAME: Full name of customer</p>
              <p>$MERCHANT_NAME: Name of merchant</p>

              <p style={{ marginTop: "2em" }}>
                <strong>Result: </strong>
                {cancelText}
              </p>
            </Col>
          </Row>
        </div>
        <Col xs={24} span={24} style={{ marginTop: "2em" }}>
          <Space style={{ width: "100%", justifyContent: "end" }}>
            <FormItem>
              <Button
                loading={loading}
                type="primary"
                size="large"
                htmlType="submit"
              >
                SAVE
              </Button>
            </FormItem>
          </Space>
        </Col>
      </Form>
    </>
  );
};
