import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoutes } from "router";
import { getToken } from "utils/auth";

export const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();

    if (!token && location.pathname != "/login") {
      return navigate("/login");
    }
    if (location.pathname == "/") {
      if (token) {
        const route = adminRoutes[0].children
          ? adminRoutes[0].path + "/" + adminRoutes[0].children[0].path
          : adminRoutes[0].path;

        navigate(route || "/login");
      } else {
        navigate("/login");
      }
    }

    handleScrollToTop();
  }, [location]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return location;
};
