import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Popover, Space, Spin, Table } from "antd";
import { renewPackageApi } from "api/renew-package.api";
import { Pagination } from "components/Pagination";
import React, { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { RenewPackage } from "types/renew";
import { formatVND, getTitle } from "utils";
import { $url } from "utils/url";
import { RenewPackageModal } from "./components/RenewPackageModal";

const { ColumnGroup, Column } = Table;

export const RenewPackagePage = () => {
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 10,
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RenewPackage[]>([]);
  const [total, setTotal] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedRenewPackage, setSelectedRenewPackage] =
    useState<RenewPackage>();
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {}, []);

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = async () => {
    setLoading(true);
    const res = await renewPackageApi.findAll(query);
    setLoading(false);
    setData(res.data.renewPackages);
    setTotal(res.data.total);
  };

  const handleDelete = async (renewPackage: RenewPackage) => {
    setLoading(true);
    try {
      await renewPackageApi.delete(renewPackage.id);
      fetchData();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="filter-container">
        <Space>
          <div className="filter-item ">
            <Button
              onClick={() => {
                setVisibleModal(true);
                setModalStatus("create");
                setSelectedRenewPackage(undefined);
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Add new
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table pagination={false} rowKey="id" dataSource={data}>
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Day" dataIndex="day" />
          <Column
            title="Amount"
            render={(t, record: RenewPackage) => (
              <span>${formatVND(record.amount)}</span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record: RenewPackage) => (
              <span>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedRenewPackage(record);
                      setVisibleModal(true);
                      setModalStatus("update");
                    }}
                  >
                    Update
                  </Button>

                  <Popconfirm
                    title="Are you sure delete?"
                    okText="Delete"
                    onConfirm={() => handleDelete(record)}
                  >
                    <Button danger>Delete</Button>
                  </Popconfirm>
                </Space>
              </span>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          onChange={({ limit, page }) => {
            query.page = page;
            query.limit = limit;
            setQuery({ ...query });
          }}
        />
      </Spin>

      <RenewPackageModal
        onSubmitOk={fetchData}
        onClose={() => setVisibleModal(false)}
        visible={visibleModal}
        renewPackage={selectedRenewPackage}
        status={modalStatus}
      />
    </div>
  );
};
