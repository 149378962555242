import {
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Dropdown, Image, Menu } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Header } from "antd/lib/layout/layout";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
import { userStore } from "store/userStore";
import { $url } from "utils/url";
import { adminRoutes, routes } from "router";
import { setToken } from "utils/auth";

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/profile">Profile</Link>
    </Menu.Item>

    <Menu.Item>
      <Link to={"/login"} onClick={() => setToken("")}>
        Log out
      </Link>
    </Menu.Item>
  </Menu>
);

export const Navbar = observer(
  ({ collapsed, toggle }: { collapsed: boolean; toggle: () => void }) => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

    useEffect(() => {
      // set breadcrumbs
      const find = adminRoutes.find((e) => e.path == location.pathname);
      if (find) {
        if (find.breadcrumb) {
          setBreadcrumbs(find.breadcrumb.split("/"));
        } else if (find.title) {
          setBreadcrumbs([find.title]);
        }
      }
    }, [location]);

    //   const menu = React.useMemo(()=> <Menu>
    //   <Menu.Item>
    //     <Link to="/profile">Cá nhân</Link>
    //   </Menu.Item>

    //   <Menu.Item>
    //     <Link to={""}>Đăng xuất</Link>
    //   </Menu.Item>
    // </Menu>,)

    return (
      <Header
        className={`site-layout-background ${collapsed ? "collapsed" : ""}`}
        style={{ padding: 0 }}
      >
        <div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              style: { color: "#000" },
              onClick: toggle,
            }
          )}
          <Breadcrumb style={{ display: "inline-block" }}>
            {breadcrumbs.map((item) => (
              <Breadcrumb.Item>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>

        <div>
          <Dropdown trigger={["click"]} overlay={menu}>
            <div style={{ cursor: "pointer" }}>
              <Avatar
                size={"large"}
                src={$url(userStore.info.avatar)}
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                }}
              >
                {userStore.info.name?.[0]}
              </Avatar>
              <CaretDownOutlined style={{ color: "#fff", marginLeft: 4 }} />
            </div>
          </Dropdown>
          {/* {userStore.info.avatar ? (
            <Image
              width={40}
              src={$url(userStore.info.avatar)}
              style={{ borderRadius: "50%" }}
              fallback={require("assets/images/user.png")}
            />
          ) : (
            <img
              width={40}
              alt=""
              style={{ borderRadius: "50%" }}
              src={require("assets/images/user.png")}
            />
          )} */}
        </div>
      </Header>
    );
  }
);
