import { Col, Form, Input, message, Modal, Row } from "antd";
import { Rule } from "antd/lib/form";
import { renewPackageApi } from "api/renew-package.api";
import { InputNumber } from "components/Input/InputNumber";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { RenewPackage } from "types/renew";
import { Staff } from "types/staff";

const rules: Rule[] = [{ required: true }];

export const RenewPackageModal = ({
  visible,
  status,
  renewPackage,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  status: ModalStatus;
  renewPackage?: RenewPackage;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = Form.useForm<RenewPackage>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status == "create" && visible) {
      form.resetFields();
    }
  }, [visible, status]);

  useEffect(() => {
    if (renewPackage) {
      form.setFieldsValue({ ...renewPackage });
    }
    return () => {};
  }, [renewPackage]);

  const createData = async () => {
    const valid = await form.validateFields();
    const data = { renewPackage: form.getFieldsValue() };

    setLoading(true);
    try {
      const res = await renewPackageApi.create(data);
      message.success("Create successfully!");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  const updateData = async () => {
    const valid = await form.validateFields();
    const data = { renewPackage: form.getFieldsValue() };
    setLoading(true);
    try {
      const res = await renewPackageApi.update(renewPackage?.id || 0, data);
      message.success("Update successfully!");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={status == "create" ? "Create Package" : "Update Package"}
      style={{ top: 20 }}
      width={700}
      confirmLoading={loading}
      onOk={() => {
        status == "create" ? createData() : updateData();
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={rules}>
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Day" name="day" rules={rules}>
              <InputNumber />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Amount($)" name="amount" rules={rules}>
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
