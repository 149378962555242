import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoutes } from "router";
import { userStore } from "store/userStore";
import { getToken, setToken } from "utils/auth";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleAuth();
    handleNavigation();
    return () => {};
  }, [navigation]);

  const handleNavigation = () => {
    const route = adminRoutes.find((e) => e.path == location.pathname);
    if (route?.children) {
      const findIndex = route.children.find((e) => e.index);
      const routePath =
        route.path + "/" + (findIndex?.path || route.children[0]?.path || "");
      navigation(routePath);
    }
  };

  const handleAuth = async () => {
    console.log("handle Auth");
    const token = getToken();
    if (!token) {
      navigation("/login");
    } else {
      try {
        await userStore.getProfile();
      } catch (error) {
        userStore.logout();
        navigation("/login");
      }
    }
  };

  return <>{children}</>;
};
