import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { adminRoutes } from "router";

const deviceWidth = window.innerWidth;

export const Sidebar = React.memo(
  ({ collapsed, toggle }: { collapsed: boolean; toggle: () => void }) => {
    const location = useLocation();

    const handleCloseSidebar = () => {
      if (!collapsed && deviceWidth <= 821) {
        toggle();
      }
    };

    return (
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img src={require("../../assets/images/logo.png")} alt="" />
        </div>

        <Menu
          theme="dark"
          mode="inline"
          inlineCollapsed={collapsed}
          //   activeKey={selectedKey}
          //   defaultSelectedKeys={[selectedKey]}
          selectedKeys={[location.pathname]}
        >
          {adminRoutes.map((route) => {
            if (route.children?.length) {
              return (
                <SubMenu key={route.path} icon={route.icon} title={route.title}>
                  {route.children?.map((item) => (
                    <Menu.Item
                      icon={item.icon}
                      key={item.path}
                      onClick={handleCloseSidebar}
                    >
                      {route.path && item.path && (
                        <Link to={route.path + "/" + item.path}>
                          {item.title}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                icon={route.icon}
                key={route.path}
                onClick={handleCloseSidebar}
              >
                <Link to={route.path || ""}>{route.title}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    );
  }
);
