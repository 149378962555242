import { message, Modal } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { merchantApi } from "api/merchant.api";
import { GoogleMaps } from "components/Map/GoogleMaps";

import React, { useState } from "react";
import "../styles/addressModal.scss";

interface CoordAddress {
  address: string;
  lat: number;
  lng: number;
}

export const AddressModal = ({
  visible,
  merchantId,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  merchantId: number;
  onClose: () => void;
  onSubmitOk: (address: string, lat: number, long: number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState<CoordAddress>();

  const handleSubmitForm = async () => {
    if (addressInfo) {
      onSubmitOk(addressInfo.address, addressInfo.lat, addressInfo.lng);
      onClose();
    }
  };

  return (
    <Modal
      className="address-modal"
      onCancel={onClose}
      visible={visible}
      title={"Update address"}
      confirmLoading={loading}
      onOk={handleSubmitForm}
      width={600}
    >
      <GoogleMaps onPlaceSelected={(address) => setAddressInfo(address)} />
    </Modal>
  );
};
