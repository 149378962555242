import { request } from "utils/request";
import { AxiosPromise } from "axios";


export const renewOrderApi = {
    findAll: (params?: any): AxiosPromise<any> => request({
        url: '/v1/admin/renewOrder',
        params
    }),
    create: (data: any): AxiosPromise<any> => request({
        url: '/v1/admin/renewOrder',
        data,
        method: 'post'
    }),
    update: (id: number, data: any): AxiosPromise<any> => request({
        url: `/v1/admin/renewOrder/${id}`,
        method: 'patch',
        data
    }),
    delete: (id: number): AxiosPromise<any> => request({
        url: `/v1/admin/renewOrder/${id}`,
        method: 'delete'
    }),
}
