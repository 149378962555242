import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Table,
} from "antd";
import { employeeApi } from "api/employee.api";
import { staffApi } from "api/staff.api";
import { Pagination } from "components/Pagination";
import React, { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { Staff } from "types/staff";
import { getTitle } from "utils";
import { ResetPasswordModal } from "./components/ResetPasswordModal";
import { StaffModal } from "./components/StaffModal";

const { Column } = Table;

export const StaffPage = ({ title = "Users" }) => {
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 10,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Staff[]>([]);
  const [total, setTotal] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleResetPassModal, setVisibleResetPassModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<Partial<Staff>>({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = async () => {
    setLoading(true);
    const res = await staffApi.findAll(query);
    setLoading(false);
    setData(res.data.data);
    setTotal(res.data.total);
  };

  const confirmDelete = (staff: Staff) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete ${staff.name} ?`,
      okText: "Delete",
      okButtonProps: { danger: true },
      cancelText: "Cancel",
      onOk: () => handleDelete(staff.id),
    });
  };

  const handleDelete = async (id: number) => {
    await staffApi.delete(id);
    message.success("Delete successfully");

    await fetchData();
  };

  return (
    <section className="box">
      <div className="filter-container">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Search User</label>
            <Input
              onKeyDown={(ev) => {
                if (ev.code == "Enter") {
                  setQuery({ ...query, page: 1 });
                }
              }}
              onChange={(e) => {
                let text = e.target.value;
                query.search = text;
              }}
              size="middle"
              placeholder="Search User"
            />
          </div>

          <div className="filter-item btn mobile-no-top">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                query.page = 1;
                fetchData();
              }}
            >
              Search
            </Button>
          </div>

          <div className="filter-item btn mobile-no-top">
            <Button
              onClick={() => {
                setVisibleModal(true);
                setModalStatus("create");
                setSelectedStaff({});
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              New user
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={data}
          scroll={{ x: 720 }}
        >
          <Column
            title="Username"
            dataIndex="username"
            key="username"
            render={(text, record: Staff) => {
              return (
                <div>
                  <Avatar
                    src={record.avatar}
                    size={60}
                    icon={<UserOutlined />}
                  />
                  <br />
                  <label style={{ marginTop: "10px" }} htmlFor="">
                    {text}
                  </label>
                </div>
              );
            }}
          />
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Phone" dataIndex="phone" key="phone" />
          <Column title="Email" dataIndex="email" key={"email"} />

          <Column
            title="Action"
            key="action"
            render={(text, record: Staff) => (
              <Space>
                <Button
                  type="link"
                  onClick={() => {
                    setVisibleResetPassModal(true);
                    setSelectedStaff(record);
                  }}
                >
                  Reset password
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedStaff(record);
                    setVisibleModal(true);
                    setModalStatus("update");
                  }}
                >
                  Update
                </Button>
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    confirmDelete(record);
                  }}
                >
                  Delete
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          total={total}
          currentPage={query.page}
          onChange={({ limit, page }) => {
            query.page = page;
            query.limit = limit;
            setQuery({ ...query });
          }}
        />
      </Spin>

      <StaffModal
        onSubmitOk={fetchData}
        onClose={() => setVisibleModal(false)}
        visible={visibleModal}
        staff={selectedStaff}
        status={modalStatus}
      />
      <ResetPasswordModal
        staff={selectedStaff}
        visible={visibleResetPassModal}
        closeModal={() => setVisibleResetPassModal(false)}
      />
    </section>
  );
};
