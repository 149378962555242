import { Table, Tabs } from "antd";
import { useEffect } from "react";
import { getTitle } from "utils";
import { RenewOrderComponent } from "./RenewOrder";
import { RenewPackagePage } from "./RenewPackage";

const { ColumnGroup, Column } = Table;

export const RenewPage = ({ title = "" }) => {
  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  return (
    <section className="box">
      <Tabs defaultActiveKey="1" type="line">
        <Tabs.TabPane tab="Orders" key="1">
          <RenewOrderComponent />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Packages" key="2">
          <RenewPackagePage />
        </Tabs.TabPane>
      </Tabs>
    </section>
  );
};
