import {
  BlockOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  StopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Input, message, Modal, Space, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { employeeApi } from "api/employee.api";
import { Pagination } from "components/Pagination";
import React, { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { Staff } from "types/staff";
import { EmployeeModal } from "../EmployeeModal";
import { ResetPassModal } from "../ResetPassModal";

export const StaffTab = ({ merchant }: { merchant: Partial<Merchant> }) => {
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 10,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [ModalVisible, setIsModalVisible] = useState(false);
  const [modalStatus, setModalStatus] = useState("create");
  const [selectedStaff, setSelectedStaff] = useState({});
  const [status, setStatus] = useState<ModalStatus>("create");
  const [visibleResetPassModal, setVisibleResetPassModal] = useState(false);
  const [staffInfo, setStaffInfo] = useState<Staff>();

  useEffect(() => {
    fetchData();
  }, [merchant, query]);

  const confirmDelete = (staff: Staff) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete ${staff.name} ?`,
      okText: "Delete",
      okButtonProps: { danger: true },
      cancelText: "Cancel",
      onOk: () => handleDelete(staff.id),
    });
  };

  const confirm = (staff: Staff, action: string) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to ${action} ${staff.name} ?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleBlockEmployee(staff.id, action),
    });
  };

  const handleBlockEmployee = async (id: number, action: string) => {
    switch (action) {
      case "block":
        await employeeApi.blockEmployee(id);
        message.success("Block successfully");
        break;

      case "unblock":
        await employeeApi.unblockEmployee(id);
        message.success("Unblock successfully");
        break;
    }
    await fetchData();
  };

  const handleDelete = async (id: number) => {
    await employeeApi.delete(id);
    message.success("Delete successfully");

    await fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    // const param = { merchantId: merchant.id, search: keywordSearch };
    const res = await employeeApi.findAllEmployee({
      merchantId: merchant.id,
      ...query,
    });
    setLoading(false);
    setData(res.data.employees);
    setTotal(res.data.total);
  };

  return (
    <>
      {" "}
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Search</label>
            <Input
              onKeyDown={(ev) => {
                if (ev.code == "Enter") {
                  setQuery({ ...query, page: 1 });
                }
              }}
              onChange={(ev) => {
                let text = ev.target.value;
                query.search = text;
              }}
              size="middle"
              placeholder="Search Staff"
            />
          </div>

          <div className="filter-item btn">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                query.page = 1;
                fetchData();
              }}
            >
              Search
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                setIsModalVisible(true);
                setModalStatus("create");
                setSelectedStaff({});
                setStatus("create");
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Add Staff
            </Button>
          </div>
        </Space>
      </div>
      <Table dataSource={data} pagination={false} scroll={{ x: 720 }}>
        <Column
          title="Avatar"
          key="avatar"
          render={(text, record: Staff) => (
            <>
              <Avatar src={record.avatar} size={60} icon={<UserOutlined />} />
              <p style={{ marginTop: "10px" }}>
                <b style={{ textTransform: "uppercase" }}>{record.name}</b>
              </p>
            </>
          )}
        />
        <Column
          title="Status"
          key="status"
          render={(text, record: Staff) => (
            <>
              {record.isBlocked ? (
                <div style={{ marginBottom: "5px" }}>
                  <Tag color="red">Blocked</Tag>
                </div>
              ) : null}
            </>
          )}
        />
        <Column title="Username" dataIndex="username" key="lastName" />
        <Column title="Email" dataIndex="email" key="address" />

        <Column
          title="Action"
          key="action"
          render={(text, record: Staff) => (
            <Space>
              <Button
                type="default"
                onClick={() => {
                  setVisibleResetPassModal(true);
                  setSelectedStaff(record);
                }}
              >
                Reset password
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setSelectedStaff(record);
                  setIsModalVisible(true);
                  setStatus("update");
                }}
              >
                Update
              </Button>
              {record.isBlocked ? (
                <Button
                  type="default"
                  danger
                  onClick={() => {
                    confirm(record, "unblock");
                  }}
                >
                  Unblock
                </Button>
              ) : (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    confirm(record, "block");
                  }}
                  icon={<StopOutlined />}
                >
                  Block
                </Button>
              )}
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                onClick={() => {
                  confirmDelete(record);
                }}
              >
                Delete
              </Button>
            </Space>
          )}
        />
      </Table>
      <Pagination
        total={total}
        currentPage={query.page}
        onChange={({ limit, page }) => {
          query.page = page;
          query.limit = limit;
          setQuery({ ...query });
        }}
      />
      <EmployeeModal
        onSubmitOk={fetchData}
        onClose={() => setIsModalVisible(false)}
        visible={ModalVisible}
        staff={selectedStaff}
        status={status}
        merchantId={merchant.id}
      />
      <ResetPassModal
        visible={visibleResetPassModal}
        staff={selectedStaff}
        closeModal={() => setVisibleResetPassModal(false)}
      />
    </>
  );
};
