import { Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { staffApi } from "api/staff.api";
import React, { useEffect, useState } from "react";
import { Staff } from "types/staff";

export const ResetPasswordModal = ({
  visible,
  staff,
  closeModal,
}: {
  visible: boolean;
  staff: Partial<Staff>;
  closeModal: () => void;
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [staff]);

  const handleResetPass = async () => {
    await form.validateFields();
    setLoading(true);
    const dataPost = form.getFieldValue("newPassword");
    console.log("datapost", dataPost);

    try {
      await staffApi.resetPass(staff.id || 0, { newPassword: dataPost });
      message.success("Reset password successfully");
      closeModal();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      onOk={() => handleResetPass()}
      title={`Reset password (${staff.username})`}
      visible={visible}
      confirmLoading={loading}
      onCancel={closeModal}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="newPassword"
          label="New password"
          required
          rules={[
            {
              required: true,
              message: "Please enter your new password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
