import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Switch,
} from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { UploadFile } from "antd/lib/upload/interface";
import { merchantApi } from "api/merchant.api";
import { MultiImageUpload } from "components/Upload/MultiImageUpload";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import moment from "moment";
import { string32 } from "pdfjs-dist/types/src/shared/util";
import React, { useEffect, useMemo, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { AddressModal } from "../AddressModal";

const style = {};

export const InfoTab = ({
  merchant,
  status,
  onSubmit,
  onClose,
}: {
  merchant: Partial<Merchant>;
  status: ModalStatus;
  onSubmit: () => void;
  onClose: () => void;
}) => {
  const rules: Rule[] = [{ required: true }];
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);
  const [addressInfo, setAddressInfo] = useState<{
    address: string | undefined;
    lat: number | undefined;
    long: number | undefined;
  }>();

  useEffect(() => {
    let expiredAt;
    if (merchant.expiredAt) {
      expiredAt = moment.unix(merchant.expiredAt);
    }
    form.setFieldsValue({ ...merchant, expiredAt });
    if (status === "create") form.resetFields();
  }, [merchant, status]);

  const handleSubmitForm = async () => {
    setLoading(true);
    await form.validateFields();

    let images = form.getFieldValue("merchantGalleries");

    if (status == "update") {
      images = images.map((img: any) => {
        if (typeof img == "object") {
          return img.image;
        } else {
          return img;
        }
      });
    }

    // console.log("image update", form.getFieldValue("merchantGalleries"));
    const { expiredAt, ...merchantData } = form.getFieldsValue();
    const dataPost = {
      merchant: {
        ...merchantData,
        expiredAt: moment(expiredAt).unix(),
        lat: addressInfo?.lat,
        long: addressInfo?.long,
      },
      images,
    };

    console.log("data", dataPost);
    switch (status) {
      case "create":
        await merchantApi.create(dataPost);
        console.log("dataPost", dataPost);

        message.success("Create merchant successfully!");
        form.resetFields();
        onClose();

        break;

      case "update":
        await merchantApi.update(merchant?.id || 0, dataPost);
        console.log("datapost", dataPost);

        message.success("Update merchant successfully!");
        // form.resetFields();
        break;
    }
    setLoading(false);
    onSubmit();
  };

  const fileListProp = useMemo(() => {
    if (merchant.merchantGalleries && status == "update") {
      const fileList: UploadFile<any>[] = merchant.merchantGalleries.map(
        (img) => {
          console.log("img", img);

          return {
            uid: img.id + "",

            name: img.id + "img",

            url: img.image,
          };
        }
      );
      return fileList;
    } else return [];
  }, [merchant.merchantGalleries]);

  return (
    <>
      {" "}
      <Form
        layout="vertical"
        onFinish={handleSubmitForm}
        form={form}
        initialValues={{ isEnableSendSms: false }}
      >
        <Row gutter={32}>
          <Col xl={12} xs={24}>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Name" name="name" rules={rules}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <FormItem
                  label="Address"
                  name="address"
                  className="custom-link"
                >
                  <Input
                    readOnly
                    style={{ height: "100%" }}
                    onChange={(e) => {
                      return false;
                    }}
                    addonAfter={
                      <Button
                        type="primary"
                        onClick={() => setAddressVisible(true)}
                      >
                        {merchant.address ? "Update" : "Enter a location"}
                      </Button>
                    }
                  />
                </FormItem>
              </Col>

              <Col span={24}>
                <Form.Item label="Expiry date" name="expiredAt" rules={rules}>
                  <DatePicker
                    format={process.env.REACT_APP_DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  style={style}
                  label="Phone"
                  name="contactPhone"
                  rules={rules}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  style={style}
                  label="Email"
                  name="email"
                  rules={rules}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={24}>
                <Form.Item
                  style={style}
                  label="How many SMS are sent per month?"
                  name="numberOfSmsPerMonth"
                  rules={rules}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={6} xs={12}>
                <Form.Item
                  style={style}
                  label="Send SMS to customer"
                  name="isEnableSendSms"
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="Enable"
                    unCheckedChildren="Disable"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} xs={12}>
                <Form.Item
                  style={style}
                  label="Booking online"
                  name="isEnableBookingOnline"
                  valuePropName="checked"
                >
                  <Switch checkedChildren="ON" unCheckedChildren="OFF" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xl={12} xs={24}>
            <Form.Item shouldUpdate={true}>
              {() => {
                return (
                  <Form.Item label="Avatar" name="avatar">
                    <SingleImageUpload
                      onUploadOk={(path: string) => {
                        console.log("onUploadOk:", path);
                        form.setFieldsValue({
                          avatar: path,
                        });
                      }}
                      imageUrl={form.getFieldValue("avatar")}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item shouldUpdate={true}>
              {() => {
                return (
                  <Form.Item label="Image" name="merchantGalleries">
                    <MultiImageUpload
                      onUploadOk={(fileList) => {
                        console.log("onUploadOk Multi:", fileList);
                        form.setFieldsValue({
                          merchantGalleries: fileList,
                        });
                      }}
                      imageUrl={form.getFieldValue("merchantGalleries")}
                      fileListProp={fileListProp}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: "left" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {status === "create" ? "Create" : "Update"}
          </Button>
        </Form.Item>
      </Form>
      {addressVisible && (
        <AddressModal
          visible={addressVisible}
          onClose={() => setAddressVisible(false)}
          merchantId={merchant.id || 0}
          onSubmitOk={(address, lat, long) => {
            form.setFieldsValue({ address });
            setAddressInfo({ address, lat, long });
          }}
        />
      )}
    </>
  );
};
