import { ShopOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { AdminLayout } from "layouts/AdminLayout";
import React from "react";
import { RouteObject } from "react-router-dom";
import { NotFoundPage } from "views/404/NotFoundPage";
import { CustomerPage } from "views/Customer/CustomerPage";
import { LoginPage } from "views/Login/LoginPage";
import { MerchantPage } from "views/Merchant/MerchantPage";
import { ProfilePage } from "views/Profile/ProfilePage";
import { RenewPage } from "views/Renew/RenewPage";
import { StaffPage } from "views/Staff/StaffPage";

interface Route extends RouteObject {
  title?: string;
  children?: Route[];
  icon?: React.ReactNode;
  breadcrumb?: string;
}

export const adminRoutes: Route[] = [
  {
    title: "Merchant",
    icon: <ShopOutlined />,
    path: "/merchant",
    breadcrumb: "Merchant",
    index: true,
    element: <MerchantPage title="Merchant" />,
  },
  {
    title: "Renew",
    icon: <ShopOutlined />,
    path: "/renew",
    breadcrumb: "Renew",
    index: true,
    element: <RenewPage title="Renew" />,
  },
  {
    title: "Customer",
    icon: <UserOutlined />,
    path: "/customer",
    element: <CustomerPage title="Customer" />,
  },
  // {
  //   title: "Book",
  //   icon: <ShopOutlined />,
  //   path: "/book",
  //   children: [
  //     {
  //       path: "list",
  //       title: "Book list",
  //     },
  //   ],
  // },
  {
    title: "Users",
    icon: <TeamOutlined />,
    element: <StaffPage title="Users" />,
    path: "/users",
  },
  {
    title: "Profile",
    icon: <UserOutlined />,
    path: "/profile",
    element: <ProfilePage title="Profile" />,
  },
];

const routes: Route[] = [
  {
    element: <AdminLayout />,
    children: adminRoutes,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export { routes };
