import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { authApi } from "api/auth.api";
import React, { useEffect, useState } from "react";
import { userStore } from "store/userStore";
import { getTitle } from "utils";

export const ProfilePage = ({ title }: { title: string }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  //handle submit form
  const onFinish = async (values: any) => {
    const oldPassword = form.getFieldValue("oldPassword");
    const newPassword = form.getFieldValue("newPassword");
    setLoading(true);

    try {
      const res = await authApi.passwordUpdate({
        oldPassword,
        newPassword,
      });
      form.resetFields();
      message.success("Update password successfully!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {" "}
      <Row gutter={16}>
        <Col
          className="gutter-row"
          lg={6}
          xs={24}
          style={{ marginBottom: "2em" }}
        >
          <Card title="Infomation" className="box">
            <div
              className="card-avatar"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                icon={userStore.info.name?.[0]}
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  verticalAlign: "middle",
                  margin: "auto",
                }}
                size={100}
              />
            </div>

            <Divider orientation="left" orientationMargin="0">
              Other Infomation
            </Divider>
            <p>
              <b>Name:</b> {userStore.info.name}
            </p>
            <p>
              <b>Phone:</b> {userStore.info.phone}
            </p>
            <p>
              <b>Email: </b> {userStore.info.email}
            </p>
          </Card>
        </Col>
        <Col className="gutter-row" lg={18} xs={24}>
          <Card title="Change password" className="box">
            <Form
              form={form}
              onFinish={onFinish}
              //   onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Old password"
                name="oldPassword"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter your old password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New password"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="renewPassword"
                label="Re-new password"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter re-new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  style={{ background: "black", borderColor: "black" }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
