import {
  DollarOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import { merchantApi } from "api/merchant.api";
import { Pagination } from "components/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { parseCommandLine } from "typescript";
import { getTitle } from "utils";
import { RenewModal } from "views/Renew/components/RenewModal";
import { MerchantModal } from "./components/MerchantModal";

const { Column } = Table;
const { Option } = Select;

interface MerchantQuery extends QueryParam {
  isExpired?: boolean;
}

export const MerchantPage = ({ title }: { title: string }) => {
  const [query, setQuery] = useState<MerchantQuery>({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");
  const [selectedMerchant, setSelectedMerchant] = useState<Partial<Merchant>>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Merchant[]>([]);
  const [isVisibleRenewModal, setIsVisibleRenewModal] = useState(false);

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  useEffect(() => {
    fetchMerchant();
  }, [query]);

  const deleteMerchant = async (idMerchant: number) => {
    await merchantApi.delete(idMerchant);
    message.success("Delete successfully");
    await fetchMerchant();
  };

  const confirm = (merchant: Merchant) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete ${merchant.name}?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => deleteMerchant(merchant.id),
    });
  };

  const fetchMerchant = async () => {
    setLoading(true);
    try {
      const res = await merchantApi.findAll(query);
      setData(res.data.merchants);
      setTotal(res.data.total);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleBlock = async (merchant: Merchant) => {
    setLoading(true);
    if (merchant.isBlock) {
      await merchantApi.update(merchant.id, {
        merchant: {
          isBlock: false,
        },
      });
      message.success("Unblock successfully");
    } else {
      await merchantApi.update(merchant.id, {
        merchant: {
          isBlock: true,
        },
      });
      message.success("Block successfully");
    }

    fetchMerchant();
  };

  const currentUnixTime = moment().unix();

  return (
    <section className="box">
      <div className="filter-container">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Search Merchant</label>
            <Input
              onKeyDown={(ev) => {
                if (ev.code == "Enter") {
                  setQuery({ ...query, page: 1 });
                }
              }}
              onChange={(e) => {
                let text = e.target.value;
                query.search = text;
              }}
              size="middle"
              placeholder="Search Merchant, username"
            />
          </div>

          <div className="filter-item">
            <label htmlFor="">Expired</label>
            <br />
            <Select
              allowClear
              onChange={(val) => {
                query.isExpired = val;
                setQuery({ ...query });
              }}
              style={{ width: 150 }}
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </div>

          <div className="filter-item btn search-btn-merchant">
            <Button
              onClick={() => {
                query.page = 1;
                fetchMerchant();
              }}
              type="primary"
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
          <div className="filter-item btn">
            <Button
              onClick={() => {
                setSelectedMerchant({});
                setModalStatus("create");
                setIsModalVisible(true);
                // setSelectedStaff({});
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              New merchant
            </Button>
          </div>
        </Space>
      </div>
      <div className="merchant-table">
        <Spin spinning={loading}>
          <Table dataSource={data} pagination={false} scroll={{ x: 1000 }}>
            <Column
              title="Name"
              key="name"
              render={(text, record: Merchant) => (
                <>
                  <Avatar
                    src={record.avatar}
                    size={60}
                    icon={<UserOutlined />}
                  />
                  <p style={{ marginTop: "10px", textTransform: "uppercase" }}>
                    {record.name}
                  </p>
                </>
              )}
            />

            <Column
              title="Block status"
              key="status"
              render={(text, record: Merchant) =>
                record.isBlock ? (
                  <Tag color="red">Blocked</Tag>
                ) : (
                  <Tag color="green">Unblocked</Tag>
                )
              }
            />

            <Column
              title="Contact"
              key="address"
              render={(text, record: Merchant) => (
                <>
                  <p>
                    <b>Address: </b>
                    {record.address}
                  </p>
                  <p>
                    <b>Phone: </b>
                    {record.contactPhone}
                  </p>
                  <p>
                    <b>Email: </b>
                    {record.email}
                  </p>
                </>
              )}
            />

            <Column
              title="Expiration"
              render={(text, record: Merchant) => (
                <div>
                  {record.expiredAt < currentUnixTime ? (
                    <>
                      <Tag color="red">Expired</Tag>
                    </>
                  ) : (
                    <Tag color="green">Active</Tag>
                  )}

                  {!!record.expiredAt && (
                    <>
                      <br />
                      <span>
                        {/* Expired at:{" "} */}
                        {moment
                          .unix(record.expiredAt)
                          .format(process.env.REACT_APP_DATE_FORMAT)}
                      </span>
                    </>
                  )}

                  <br />
                </div>
              )}
            />

            <Column
              title="Action"
              key="action"
              render={(text, record: Merchant) => (
                <span>
                  <Space>
                    <Button
                      icon={<DollarOutlined />}
                      type="link"
                      size="small"
                      onClick={() => {
                        setIsVisibleRenewModal(true);
                        setSelectedMerchant(record);
                      }}
                    >
                      Renew
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        // fetchMerchantById(record.id);
                        setIsModalVisible(true);
                        setModalStatus("update");
                        setSelectedMerchant(record);
                      }}
                    >
                      Update
                    </Button>

                    <Popconfirm
                      onConfirm={() => handleToggleBlock(record)}
                      title={
                        record.isBlock
                          ? "Are you sure unblock this merchant?"
                          : "Are you sure block this merchant?"
                      }
                    >
                      <Button>{record.isBlock ? "Unblock" : "Block"}</Button>
                    </Popconfirm>

                    <Button danger onClick={() => confirm(record)}>
                      Delete
                    </Button>
                  </Space>
                </span>
              )}
            />
          </Table>
          <Pagination
            total={total}
            currentPage={query.page}
            onChange={({ limit, page }) => {
              query.page = page;
              query.limit = limit;
              setQuery({ ...query });
            }}
          />
        </Spin>

        <MerchantModal
          isModalVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSubmitOk={fetchMerchant}
          status={modalStatus}
          merchant={selectedMerchant}
        />

        <RenewModal
          visible={isVisibleRenewModal}
          onClose={() => setIsVisibleRenewModal(false)}
          onSubmitOk={fetchMerchant}
          merchant={selectedMerchant}
        />
      </div>
    </section>
  );
};
