import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const staffApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/staff",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/staff",
      data,
      method: "post",
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/staff/${id}`,
      method: "patch",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/staff/${id}`,
      method: "delete",
    }),
  resetPass: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/staff/${id}/password/reset`,
      method: "post",
      data,
    }),
};
