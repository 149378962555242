import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { customerApi } from "api/customer.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useEffect, useState } from "react";
import { Customer } from "types/customer";

export const CustomerModal = ({
  visible,
  customer,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  customer: Partial<Customer>;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = useForm();
  const rules: Rule[] = [{ required: true }];
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(customer.customerProfile);
  }, [customer]);

  const handleUpdateCustomer = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const dataPost = form.getFieldsValue();
      await customerApi.update(customer.id || 0, { customerProfile: dataPost });
      message.success("Update customer successfully");
      onSubmitOk();
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Update customer"
      visible={visible}
      onCancel={onClose}
      onOk={handleUpdateCustomer}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Firstname" name="firstName" rules={rules}>
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Lastname" name="lastName" rules={rules}>
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Gender" name="gender" rules={rules}>
              <Select
              // defaultValue="male"
              // onChange={handleChange}
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Email" name="email">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Address" name="address">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="City" name="city">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="State" name="state">
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Form.Item label="Avatar" name="avatar">
                <SingleImageUpload
                  onUploadOk={(path: string) => {
                    console.log("onUploadOk:", path);
                    form.setFieldsValue({
                      avatar: path,
                    });
                  }}
                  imageUrl={form.getFieldValue("avatar")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};
