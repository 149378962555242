import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const employeeApi = {
  findAllEmployee: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/employee",
      params,
    }),

  createEmployee: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/employee",
      data,
      method: "post",
    }),

  updateEmployee: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/employee/${id}/update`,
      data,
      method: "post",
    }),

  blockEmployee: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/employee/${id}/block`,
      method: "patch",
    }),

  unblockEmployee: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/employee/${id}/unblock`,
      method: "patch",
    }),
  resetPassword: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/employee/${id}/password/reset`,
      data,
      method: "patch",
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/employee/${id}`,
      method: "delete",
    }),
};
