import { Modal, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { InfoTab } from "./TabPane/InfoTab";
import { SmsSetupPage } from "./TabPane/SmsTab";
import { StaffTab } from "./TabPane/StaffTab";

const { TabPane } = Tabs;

const style = { padding: "8px 0" };

export const MerchantModal = ({
  isModalVisible,
  status,
  merchant,
  onSubmitOk,
  onClose,
}: {
  isModalVisible: boolean;
  status: ModalStatus;
  merchant: Partial<Merchant>;
  onSubmitOk: () => void;
  onClose: () => void;
}) => {
  const [form] = useForm();
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    if (status === "create") form.resetFields();
    setActiveKey("1");
  }, [isModalVisible]);

  return (
    <Modal
      title={status === "create" ? "Create Merchant" : "Update Merchant"}
      onCancel={onClose}
      style={{ top: 20 }}
      visible={isModalVisible}
      footer={false}
      // onOk={handleSubmitForm}
      width="100vw"
    >
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onTabClick={(key) => {
          setActiveKey(key);
        }}
      >
        <TabPane tab="Info" key="1">
          <InfoTab
            merchant={merchant}
            status={status}
            onSubmit={onSubmitOk}
            onClose={onClose}
            // closeModal={() => closeModal}
          />
        </TabPane>

        {/* hide Staff tab if status == create */}
        {status === "create" ? null : (
          <>
            <TabPane tab="Staff" key="2">
              <StaffTab merchant={merchant} />
            </TabPane>
            <TabPane tab="SMS Setup" key="3">
              <SmsSetupPage
                merchantId={merchant.id || 0}
                visible={isModalVisible}
                status={status}
              />
            </TabPane>
          </>
        )}
      </Tabs>
    </Modal>
  );
};
