import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import React, { useState } from "react";
import { getToken } from "utils/auth";
import { $url } from "utils/url";

interface SingleImageUploadProps {
  uploadUrl?: string;
  imageUrl: string;
  onUploadOk: (path: string) => void;
}

export const SingleImageUpload = ({
  uploadUrl = process.env.REACT_APP_API_URL + "/v1/admin/staff/upload",
  imageUrl,
  onUploadOk,
}: SingleImageUploadProps) => {
  const [loading, setLoading] = useState(false);
  console.log("render - SingleImageUpload", imageUrl);

  const beforeUpload = (file: File) => {
    const isImg = file.type.includes("image");
    if (!isImg) {
      message.error("Only accept image format");
    }

    return isImg;
  };
  const handleChange = (info: UploadChangeParam<any>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      onUploadOk(process.env.REACT_APP_API_URL + info.file.response.data.path);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={uploadUrl}
      headers={{
        token: getToken() || "",
      }}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img src={$url(imageUrl)} alt="avatar" style={{ width: "100%" }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};
