import React, { useEffect, useState } from "react";

import "styles/App.scss";
import "styles/AntDesign.scss";
import "antd/dist/antd.less";
import "./styles/menu.scss";
import { useRoutes } from "react-router-dom";
import { routes } from "router";
import { ConfigProvider } from "antd";
import { useRouter } from "hooks/useRouter";
import { userStore } from "store/userStore";

function App() {
  const element = useRoutes(routes);
  const [isLoaded, setIsLoaded] = useState(false);
  useRouter();

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      if (userStore.token) {
        await userStore.getProfile();
      }
    } catch (error) {
      userStore.logout();
    } finally {
      setIsLoaded(true);
    }
  };

  return (
    <ConfigProvider>
      <div className="App">{element}</div>
    </ConfigProvider>
  );
}

export default App;
