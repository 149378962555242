import { Col, Form, Input, message, Modal, Row } from "antd";
import { Rule } from "antd/lib/form";
import { staffApi } from "api/staff.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { Staff } from "types/staff";

const rules: Rule[] = [{ required: true }];

export const StaffModal = ({
  visible,
  status,
  staff,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  status: ModalStatus;
  staff: Partial<Staff>;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = Form.useForm<Staff>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status == "create" && visible) {
      form.resetFields();
    }
  }, [visible, status]);

  useEffect(() => {
    form.setFieldsValue({ ...staff });
    return () => {};
  }, [staff]);

  const createData = async () => {
    const valid = await form.validateFields();
    const data = { staff: form.getFieldsValue() };

    setLoading(true);
    try {
      const res = await staffApi.create(data);
      message.success("Create staff successfully!");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  const updateData = async () => {
    const valid = await form.validateFields();
    const data = { staff: form.getFieldsValue() };
    setLoading(true);
    try {
      const res = await staffApi.update(staff?.id || 0, data);
      message.success("Update staff successfully!");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={status == "create" ? "Create Staff" : "Update Staff"}
      style={{ top: 20 }}
      width={700}
      confirmLoading={loading}
      onOk={() => {
        status == "create" ? createData() : updateData();
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Username" name="username" rules={rules}>
              <Input placeholder="" />
            </Form.Item>
          </Col>

          {status == "create" && (
            <Col span={12}>
              <Form.Item label="Password" name="password" rules={rules}>
                <Input placeholder="" />
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <Form.Item label="Name" name="name" rules={rules}>
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Phone" name="phone" rules={rules}>
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Email" name="email">
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Form.Item label="Avatar" name="avatar">
                <SingleImageUpload
                  onUploadOk={(path: string) => {
                    console.log("onUploadOk:", path);
                    form.setFieldsValue({
                      avatar: path,
                    });
                  }}
                  imageUrl={form.getFieldValue("avatar")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};
