import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import { renewOrderApi } from "api/renew-order.api";
import { renewPackageApi } from "api/renew-package.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { RenewPackage } from "types/renew";
import { Staff } from "types/staff";
import { formatVND } from "utils";

const rules: Rule[] = [{ required: true }];
const { Option } = Select;

export const RenewModal = ({
  visible,
  merchant,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  merchant?: Partial<Merchant>;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = Form.useForm<Staff>();
  const [loading, setLoading] = useState(false);
  const [renewPackages, setRenewPackages] = useState<RenewPackage[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<Partial<Merchant>>();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (merchant) {
      setSelectedMerchant(merchant);
    }
    return () => {};
  }, [merchant]);

  useEffect(() => {
    fetchRenewPackages();
  }, []);

  const fetchRenewPackages = async () => {
    const res = await renewPackageApi.findAll({ page: 1, limit: 0 });
    setRenewPackages(res.data.renewPackages);
  };

  const createData = async () => {
    const valid = await form.validateFields();
    const data = {
      renewPackageId: form.getFieldValue("renewPackageId"),
      merchantId: selectedMerchant?.id,
    };

    setLoading(true);
    try {
      const res = await renewOrderApi.create(data);
      message.success("Renew successfully!");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={"Renew"}
      style={{ top: 20 }}
      width={500}
      confirmLoading={loading}
      onOk={() => {
        createData();
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Package" name="renewPackageId" rules={rules}>
              <Select style={{ width: "100%" }}>
                {renewPackages.map((renewPackage) => (
                  <Option value={renewPackage.id}>
                    {renewPackage.name} - ${formatVND(renewPackage.amount)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
