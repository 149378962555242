import { Layout } from "antd";
import { AuthProvider } from "provider/AuthProvider";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { Sidebar } from "./components/Sidebar";
import "./styles/AdminLayout.scss";

const { Header, Sider, Content } = Layout;

const deviceWidth = window.innerWidth;

export const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(() => deviceWidth <= 720);
  const [siteLayoutMarginLeft, setSiteLayoutMarginLeft] = useState(200);
  const [selectedKey, setSelectedKey] = useState("1");

  const toggle = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffect(() => {
    if (collapsed) {
      setSiteLayoutMarginLeft(80);
    } else {
      setSiteLayoutMarginLeft(200);
    }
  }, [collapsed]);

  useEffect(() => {
    // setInterval(() => {
    //   setSelectedKey("2");
    // }, 3000);
  }, []);

  return (
    <AuthProvider>
      <Layout>
        <Sidebar
          collapsed={collapsed}
          toggle={() => setCollapsed(!collapsed)}
        />

        <Layout
          className="site-layout"
          style={{ marginLeft: siteLayoutMarginLeft }}
        >
          {/* navbar */}
          <Navbar collapsed={collapsed} toggle={toggle} />

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 12,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </AuthProvider>
  );
};
