import {
  ExclamationCircleOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Input, message, Modal, Space, Spin, Table, Tag } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Column from "antd/lib/table/Column";
import { customerApi } from "api/customer.api";
import { Pagination } from "components/Pagination";
import React, { useEffect, useState } from "react";
import { Customer } from "types/customer";
import { QueryParam } from "types/query";
import { getTitle } from "utils";
import { CustomerModal } from "./Components/CustomerModal";

export const CustomerPage = ({ title }: { title: string }) => {
  const [data, setData] = useState<Partial<Customer>[]>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState<Partial<Customer>>();
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  useEffect(() => {
    fetchCustomer();
  }, [query]);

  const fetchCustomer = async () => {
    setLoading(true);
    const res = await customerApi.findAll(query);

    setData(res.data.customers);
    setTotal(res.data.total);
    setLoading(false);
  };

  const confirm = (customer: Customer, action: string) => {
    const customerName = `${customer.customerProfile.firstName} ${customer.customerProfile.lastName}`;
    Modal.confirm({
      title: `Confirm ${action}`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to ${action} ${customerName} ?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleBlockCustomer(customer.id, action),
    });
  };

  const handleBlockCustomer = async (id: number, action: string) => {
    console.log("action", action);

    switch (action) {
      case "block":
        await customerApi.block(id);
        message.success("Block successfully");
        break;

      case "unblock":
        await customerApi.unblock(id);
        message.success("Unblock successfully");
        break;
    }
    await fetchCustomer();
  };

  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Search Customer</label>
            <Input
              onKeyDown={(ev) => {
                if (ev.code == "Enter") {
                  setQuery({ ...query, page: 1 });
                }
              }}
              onChange={(e) => {
                let text = e.target.value;
                query.search = text;
              }}
              size="middle"
              placeholder="Search Customer"
            />
          </div>
          <div className="filter-item btn">
            <Button
              onClick={() => {
                query.page = 1;
                fetchCustomer();
              }}
              type="primary"
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </Space>
      </div>
      <Spin spinning={loading}>
        <Table dataSource={data} pagination={false} scroll={{ x: 720 }}>
          <Column
            width={150}
            title="Name"
            key="name"
            render={(text, record: Customer) => (
              <>
                <Avatar
                  src={record.customerProfile.avatar}
                  size={60}
                  icon={<UserOutlined />}
                />

                <p style={{ marginTop: "10px" }}>
                  {`${record.customerProfile.firstName} ${record.customerProfile.lastName}`}{" "}
                </p>
              </>
            )}
          />
          <Column
            title="Gender"
            key="gender"
            width={100}
            render={(text, record: Customer) => (
              <>{record.customerProfile.gender}</>
            )}
          />
          <Column
            title="Email"
            key="email"
            width={250}
            render={(text, record: Customer) => (
              <>{record.customerProfile.email}</>
            )}
          />
          <Column title="Phone" key="phone" dataIndex="phone" />

          <Column
            title="Status"
            key="status"
            render={(text, record: Customer) => (
              <>
                {record.isBlocked ? (
                  <>
                    <Tag style={{ marginBottom: "1em" }} color="orange">
                      Blocked
                    </Tag>
                    <br />
                  </>
                ) : null}
                {record.isDeleted ? (
                  <>
                    {" "}
                    <Tag style={{ marginBottom: "1em" }} color="red">
                      Deleted
                    </Tag>
                    <br />
                  </>
                ) : null}
                {record.customerProfile.isAcceptMarketing ? (
                  <>
                    <Tag style={{ marginBottom: "1em" }} color="green">
                      Accept Marketing
                    </Tag>
                    <br />
                  </>
                ) : null}
              </>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record: Customer) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    setSelectCustomer(record);
                    console.log("record", record);
                  }}
                >
                  Update
                </Button>
                {record.isBlocked ? (
                  <Button
                    type="default"
                    danger
                    onClick={() => confirm(record, "unblock")}
                  >
                    Unblock
                  </Button>
                ) : (
                  <Button
                    danger
                    type="primary"
                    onClick={() => confirm(record, "block")}
                  >
                    Block
                  </Button>
                )}
              </Space>
            )}
          />
          <Column
            title="Note"
            key="note"
            render={(text, record: Customer) => (
              <>{record.customerProfile.note}</>
            )}
          />
        </Table>
        <Pagination
          total={total}
          currentPage={query.page}
          onChange={({ limit, page }) => {
            query.page = page;
            query.limit = limit;
            setQuery({ ...query });
          }}
        />
      </Spin>
      <CustomerModal
        visible={visible}
        customer={selectCustomer || {}}
        onClose={() => setVisible(false)}
        onSubmitOk={fetchCustomer}
      />
    </section>
  );
};
