import React, { useRef } from "react";
import { Pagination as AntPagination, PaginationProps } from "antd";

interface IPagination {
  total: number;
  onChange: ({ page, limit }: { page: number; limit: number }) => void;
  currentPage: number;
}

export const Pagination = ({ total, onChange, currentPage }: IPagination) => {
  return (
    <AntPagination
      current={currentPage}
      style={{ marginTop: 12 }}
      total={total}
      showSizeChanger
      onChange={(page, limit) => {
        onChange({
          page,
          limit,
        });
      }}
      onShowSizeChange={(limit) => {
        onChange({
          page: currentPage,
          limit,
        });
      }}
      showQuickJumper
      showTotal={(total) => `Total ${total} records`}
    />
  );
};
